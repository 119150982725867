import React from 'react'

function Welcome() {
    return (
        <section>
            <div className="block color-scheme-1">
                
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-10 col-lg-9">
                            <div className="section-sub-title center">
                                <article className="section-title-body white">
                                    <h1 className="head-title">
                                        <span>"The Future</span> Is An unknown, But Somewhat<span> Predictable Unknown.</span> To Look To The Futures We Must First Look Back Upon The Past. That Is Where The Seeds Of The Future Were Planted. I Never Think Of The Future. It Comes Soon Enough."
                                        <br/><span> Albert Einstein</span></h1>
                                    <p className="head-text">
                                        What is the future? This is something, that we are going today! Every small step forward taken by one person, it is not enough for the humanity, but every step pushes the humanity to tommorow! If you want to find any limits, you only should step over borders! An evalution like time - go ahead or will be a looser forever!
                                    </p>
                                </article>
                            </div>   
                        </div>
                    </div>
                </div>

            </div>
        </section>
    )
}

export default Welcome
