import React from 'react';
import Header from '../components/Header/Header';
import Welcome from '../components/Welcome/Welcome';
import About from '../components/About/About';
import Service from '../components/Service/Service';
import Portfolio from '../components/Portfolio/Portfolio';
import Blogs from '../components/Blogs/Blogs';
import Contact from '../components/Contact/Contact';
import Map from '../components/Map';
import Footer from '../components/Footer/Footer';
import SimpleSlider from '../components/Sliders/Slick-slider'
import bg_vide from './../videos/bigway.mp4'

function Home() {
  return (
    <>
      <div  id="home" className="full-screen-block">
        <video autoPlay loop muted className="bg_video">
                <source src={bg_vide} type="video/mp4"></source>
        </video>
        <div className="bg-inner-dark"></div>
        <Header/>
        <SimpleSlider/>
      </div>
      <Welcome/>
      <About/>
      <Service/>
      <Portfolio/>
      <Blogs/>
      <Contact/>
      <Map/>
      <Footer/>
    </>
  );
}

export default Home;