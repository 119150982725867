import React from 'react'
const services = [
    {
        _id:'001',
        classIcon:'ion-ios-cog-outline',
        title:'SORTWARE',
        text:'We have rich experience in the software development in different fields such as a finance accounting, enterprise, consumer services and corporate intersystem intergration.'
    },
    {
        _id:'002',
        classIcon:'ion-ios-browsers',
        title:'ARTIFICIAL INTELLIGENCE',
        text:'The main field of our exprtise is the use of AI in the industry and agriculture - object recognition, reading and classification of text in 3D, using AI for statistical analysis.'
    },
    {
        _id:'003',
        classIcon:'ion-android-apps',
        title:'MOBILE APPLICATION',
        text:'The use of mobile devices for the purpose of automating business processes. Research into the "user experience" of mobile devices and other input interfaces.'
    },
    {
        _id:'004',
        classIcon:'ion-social-android-outline',
        title:'ROBOTICS',
        text:'The main task of our company is to create specialized robotic devices that facilitate and automate the work process of employees in industry and agriculture.'
    },
    {
        _id:'005',
        classIcon:'ion-code-working',
        title:'AUTOMATION',
        text:'The main task of out company are Automation as a "front-end" for AI and Robotics processes, as well as optimization of business processes and employee productivity.'
    },
    {
        _id:'006',
        classIcon:'ion-ios-settings',
        title:'ACCOUNTING',
        text:'Integration with accounting system help automate current business processes. It can open up new opportunities for the business.'
    }
]
function Service() {
    return (
        <>
        <section  id="services" className="single-bg">
                
                <div className="section-block-title">
                    <div className="bg-dark"></div>
                    <div className="container">
                        <div className="section-sub-title center">
                            <article className="section-title-body white">
                                <h1 className="head-title">Interesting <span>Services</span> </h1>
                            </article>
                        </div>    
                    </div>
                    <div className="container">
                        <div className="row">
                            {services.map((service)=>(
                            <div className="col-xxl-4 col-md-6"  key={service._id}>
                                <div className="block-feature-icon left">
                                    <div className="icon hi-icon-color hi-icon-effect">
                                        <span className="hi-icon"><i className={service.classIcon}></i></span>
                                    </div>
                                    <div className="block-title">
                                        <h4>{service.title}</h4> 
                                        <p>{service.text}</p>
                                    </div>
                                </div>
                            </div>
                            ))}
                        </div>
                    </div>
                    <div className="h50"></div>
                </div>
        </section>
        </>
    )
}

export default Service
