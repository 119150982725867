import React from "react";
import BlogItem from "./BlogItem";

const blogs = [
  {
    _id: "001",
    title: "Startup Tech BBQ konferencē",
    text: `AIRoboto ir jaunizveidots uzņēmums atbalsta LIAA Latvija.
    AIRoboto piedalījās Startup "Tech BBQ" konferencē un pasākumā Kopenhāgenā (Dānijā) no 2022. gada 14. līdz 16.
    septembrim ar Latvijas Investīciju un attīstības aģentūras (LIAA) atbalstu saskaņā ar Līgumu (Nr. SKV-L-
    2022/352 no 15.08.2022). Līguma mērķis ir sniegt atbalstu Atbalsta saņēmējam saskaņā ar Eiropas Reģionālās attīstības fonda projektu „Starptautiskās konkurētspējas veicināšana” (projekta identifikācijas numurs 3.2.1.2./16/I/001).
    Mūsu uzņēmums AIRoboto prezentēja dažus projektus:
    1) Warehouse, Industrial. Galvenā ideja ir objektu atpazīšana noliktavā/ražošan. 
    2) IoT, Sensor, Security IoT. Galvenā ideja ir mikrokontrolleru izmantošana un dizains, kas vāc dažādus datus. Šādus kontrolierus var apvienot domēnos un, izmantojot vārteju vai domēna kontrolleri, pārsūtīt apstrādātos datus uz privātu mākoni.
    3) Bees Cloud. Mūsu risinājuma mērķis ir sniegt biškopjiem informāciju par stropa stāvokli iesācējiem biškopjiem un biškopības uzņēmumiem.
    `,
    text_eng: `AIRoboto is a startup company supported by LIAA Latvia.
    AIRoboto participated in the Startup "Tech BBQ" conference and event in Copenhagen (Denmark) from September 14 to 16, 2022 with the support of the Latvian Investment and Development Agency (LIAA) in accordance with the Agreement (No. SKV-L-2022/352 from 15.08.2022).The purpose of the agreement is to provide support to the Beneficiary in accordance with the European Regional Development Fund project "Promotion of International Competitiveness" (project identification number 3.2.1.2./16/I/001). 
    Our company AIRoboto presented some projects:
    1) Warehouse, Industrial . The main idea is the recognition of objects in the warehouse/production.
    2) IoT, Sensor, Security IoT. The main idea is the use and design of microcontrollers that collect various data. Such controllers can be combined into domains and, using a gateway or domain controller, transfer processed data to a private cloud.
    3) Bees Cloud. Our solution is aimed at providing beekeepers with information about the state of the hive for beginner beekeepers and beekeeping business.
    `,
    url: "/",
    src: "img/preview/blog_188425329.jpg",
    day: "19",
    month: "September, 2022",
    author: "Admin",
    comments: "",
    footerPicture: "img/preview/image_footer_blog_news.png",
  },
  // {
  //     _id:'001',
  //     title:'Machine Leaning Around',
  //     text:'Machine learning is a method of data analysis that automates analytical model building. It is a branch of artificial intelligence based on the idea that systems can learn from data, identify patterns and make decisions with minimal human intervention. But data is source and all ML hard depends from data. If you make your ML on "dirty data", analytical model will be useless. ML is focused by data and around data.',
  //     url:'/',
  //     src:'img/preview/blog_188425329.jpg',
  //     day:'01',
  //     month:'April',
  //     author:'Admin',
  //     comments:''
  // },
  {
    _id: "002",
    title: "Raspberry Pi in the IoT world.",
    text: "Raspberry Pi is a great microcomputer in your pocket. The sufficient power of the microcomputer and the presence of a variety of network interfaces provide an excellent basis for the creation of 111 devices. An additional advantage is the choice of operating systems, which allows the use of various developed libraries without complex adaptation to a new operating system. Various input-output channels allow you to connect various sensors, both digital and analog, depending on your needs. However, one of the disadvantages of the platform is the high power consumption, for simple tasks that can be solved by microcontrollers, but in more complex tasks, the platform is unmatched.",
    url: "/",
    src: "img/preview/blog_408530046.jpg",
    day: "14",
    month: "April, 2022",
    author: "Admin",
    comments: "",
  },
  {
    _id: "003",
    title: "Mobile application.",
    text: "Mobile application are not only entertainment. Mobile application can play vital role in bussiness, in logistics, in recognizing packets and documents. For example, we can bind ERP system with mobile application and can use in warehouse for finding any box. We can additional reality in mobile app, for hightlinding a position where this container in warehouse. We can use application for accounting proposes, for identification materials and productions in What, When, How.",
    url: "/newPage/Conf",
    src: "img/preview/blog_225343344.jpg",
    day: "20",
    month: "Jan, 2022",
    author: "Admin",
    comments: "",
  },
];

function Blogs() {
  return (
    <>
      <section id="blog" className="single-bg">
        <div className="section-block-title">
          <div className="bg-dark"></div>

          <div className="container">
            <div className="section-sub-title center">
              <article className="section-title-body white">
                <h1 className="head-title">
                  Last <span>News</span> from AIRoboto
                </h1>
              </article>
            </div>
          </div>
          <div className="container">
            <div className="row">
              {blogs.map((blog) => (
                <div className="col-md-6 col-xl-4" key={blog._id}>
                  <BlogItem
                    title={blog.title}
                    text={blog.text}
                    text_eng={blog.text_eng}
                    url={blog.url}
                    src={blog.src}
                    day={blog.day}
                    month={blog.month}
                    author={blog.author}
                    //comments={blog.comments}
                    footerPicture={blog.footerPicture}
                  />
                </div>
              ))}
            </div>
          </div>
          <div className="h50"></div>
        </div>
      </section>
    </>
  );
}

export default Blogs;
