import React, { Component } from "react";
import Slider from "react-slick";
import PortfolioItem from './../Portfolio/PortfolioItem'
export default class SliderThreeItem extends Component {

  render() {
    const settings = {
      dots: true,
      arrows:false,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 3,
      autoplay: true,
      autoplaySpeed: 400000,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1
          }
        }
      ]
    };
    return (
      <>
     
        <Slider {...settings}>
        <div className="item">
            <PortfolioItem portfolioUrl={"/"} portfolioTitle={"Software developing"} portfolioInfo={"Vital part of engeneering"} 
            portfolioDesc={" Software developing is a vital part of the modern world. The main task of software development is to create software products with quality  and opportunities corresponding to modern requirements in business tasks. Software development problem is intangibility and the possibility of rapid change even during development. Despite flexibility in development, this gives rise to the problem that the product is never finished and is constantly being refined and adapted. Such an approach in which the software product is constantly being improved has a positive side - the product adapts to emerging new requirements, while the basic system remains the same. Modern systems of development rules are built on the principles of \"Agile\", that allow you to develop software products, adapt, scale and support, including implementing new futures."}
            portfolioSrc={"img/preview/blog1.jpg"} portfolioAlt={"blog1"}/>
        </div>
        <div className="item">
            <PortfolioItem portfolioUrl={"/"} portfolioTitle={"Mobile application"} portfolioInfo={"Mobile app"} 
            portfolioDesc={"Mobile applications aren't only entertainment.    Mobile applications can play vital role in bussiness, in logistics, in recognizing packets and documents. For example, We can bind ERP system with mobile app and can use in warehouse for finding any box. We can use additional reality in mobile app, for hightlinding a position where this container/box in warehouse. We can use mobile app for accounting proposes, for identification materials and productions in What, When and How."}
            portfolioSrc={"img/preview/blog2.jpg"} portfolioAlt={"blog2"}/>
        </div>
        <div className="item">
            <PortfolioItem portfolioUrl={"/"} portfolioTitle={"IoT Smart Sensors"} portfolioInfo={" IoT, Sensors and on the way to understanding reality."} 
            portfolioDesc={" Monitoring and remote control is one of the important part of robots and monitoring automation using IoT (Internet of Things) devices. Monitoring, despite different readable characteristics, can be divided into two groups - characteristics transmitted in the online mode and characteristics transmitted in burst mode. For example, a flying robot transmitted tagged video is a typical representative of the first group. Together with the advantages, like the transmission of information online and, in fact, the removal of computational work on information processing and online processing at capacities significantly exceeding the power of the onboard robot, there are disadvantages - this is the need to have a constant reliable connection, and the requirement for autonomy in case communication, the robot must find its own way back, while not creating an emergency for other participants in the interaction. The second type of device - sends data in batches saving traffic and energy. In any case, monitoring is an essential part of understanding the quality of work and the possible diagnosis of problems, as well as prevention of possible breakdowns. Remote management allows you to update and fix non-critical issues remotely, which saves equipment downtime. "}
            portfolioSrc={"img/preview/blog3.jpg"} portfolioAlt={"blog"}/>
        </div>
        <div className="item">
            <PortfolioItem portfolioUrl={"/"} portfolioTitle={"Agricultural robots and drones"} portfolioInfo={"Using robots in agriculture"} 
            portfolioDesc={" Using robotics in agriculture applying has certainly positive moment in growing useful plants. Definitely, some part of operations, like plowing the soil or harvesting are a litle useless in a current time, but can be useful in a part of care about agricultural plants and has advantages in operations likes cultivation the soil or removing useless plants-weeds. Furthermore, robotics can make infiltrate a doze organically mineral fertilizers for stimulation growing plants in close to root and, as a result, limited expenses that provide a restricted impaction on natural environment. Current agricultural technics is founded on GMO plants, like more resistanse against herbicides, and using a lot of herbicides on full real producing! In the future, we are getting the soil, like a biological desert, in which we can grow only on type of plants. In addition, we will get erosion of the soil and exactly, hard impact on groundwater and health of customers, local citizens.  Clearly, that robotics can avoid increasing hard, handmake working, such as extracting weeds, can be possible recognizing useful and useless plants, furthermore, we can grow different type plants to compensate impaction on the soil. It means, that the soil can be regenerated faster in natural way then in other artificial cases. "}
            portfolioSrc={"img/preview/blog4.jpg"} portfolioAlt={"blog"}/>
        </div>
        <div className="item">
            <PortfolioItem portfolioUrl={"/"} portfolioTitle={"Warehouse, QR-code, ERP"} portfolioInfo={"Using our technology in warehouse"} 
            portfolioDesc={" Using robots in warehouse more productive and maintain work of staffs, optimization a business process of company, upgrading of quality working process. The most difficult and the stressful business process in a warehouse is recounting goods and materials. Sometimes, this action temporary blocks ordinary working process in a warehouse. In addition, it takes extra warehouse technics and staffs.If we could use a FLIGHT ROBOTICS DRONE with LASER SCANNER, we would complete this operation faster and less labor intensive. In addition, we can make recounting goods, without additional technics and, for example, in night shift time, with minimum staff. We can use rotary laser for estimating (How much the cell has filled?), for understanding such parameters as weight or volume for comparing with data in a computer accounting system. And, definitely, the drone would take photos and make video for approving a result, if something can not be recognizing."}
            portfolioSrc={"img/preview/blog5.jpg"} portfolioAlt={"blog"}/>
        </div>
        <div className="item">
            <PortfolioItem portfolioUrl={"/"} portfolioTitle={"Proccesing bussiness flow with AI and ML"} portfolioInfo={"Applications of AI and ML"} 
            portfolioDesc={" Next age is becoming with robotics, AI. Robotics can make work more safety, easy. Robots can perform tasks in hard an unsafety environment.Robots can not be tired, can be faster. Robot can execute routine task, which often boring for humans. "}
            portfolioSrc={"img/preview/blog6.jpg"} portfolioAlt={"blog"}/>
        </div>
        <div className="item">
            <PortfolioItem portfolioUrl={"/"} portfolioTitle={"To Infinity and Beyond"} portfolioInfo={"Look Up"}
            portfolioDesc={" Development in the modern world is always the principle of going beyond its boundaries. Software development is always pushing the boundaries of what is possible. New equipment makes it possible to do today what yesterday was still impossible or unrealistic. The development of technology always pushes the border of the impossible to far far away. "} 
            portfolioSrc={"img/preview/blog7.jpg"} portfolioAlt={"blog"}/>
        </div>
        </Slider>
        
      </>
    );
  }
}