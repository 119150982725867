import React from 'react';

function Footer() {
    return (
        <>
        <footer id="footer">
            <div className="container">
                <article className="footer-info-block">
                    <p>	&copy; AIRoboto 2022</p>
                </article>
            </div>

        </footer>
        </>
    )
}

export default Footer
