import React from 'react'
function About() {
    
    return (
        <>
            <section  id="about" className="single-bg ab2">
            <div className="section-block-title">
            <div className="bg-dark"></div>
                <div className="container">
                        <div className="section-sub-title center">
                            <article className="section-title-body white">
                                <h1 className="head-title"><span>About</span> us</h1>
                            </article>
                        </div>
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 order-lg-2 d-flex align-items-center">
                                <div className="widget-block relative">
                                <div className="h100 d-none d-lg-block"></div>
                                <h4 className="widget-title">THURSDAYWAS WAS YESTERDAY, FRIDAY WILL BE TOMORROW, BUT WHAT ABOUT TODAY?</h4>
                                <p>
                                The Symbiose (from Greek world -sumbiōsis), which means that people and robots can add each other different skills, collaboration with machines without any direct competition. For instance, “books evolution”: 300 years ago books were handmade and too expensive. And if yesterday books could be bought and they were on paper, today you buy an e-book and download it on a device in a minute! Has the Internet killed books? No, everyone today can publish a book with little costs! This is “transformation”, yesterday – boring printing books, today – interactive books with video, animation, quizzes, In addition, an author can update an e-book without reprinting, through the Internet! Yesterday, those features look like impossible, today – “ordinary features”!
                                </p>
                                    <div className="block-feature d-lg-none d-xl-block">
                                        <i className="ion-power"></i>
                                        <a href="/">INTRODUCTION</a>
                                        <p>
                                        AIRoboto is a high-tech company, the basis of which is the development of software and the implementation of advanced technologies in various business areas directly related to artificial intelligence, robotics, machine learning such as: industry, agriculture of artificial intelligence (AI), automation of the work of employees in existing areas and integration with corporate information systems (CIS).
                                        </p>
                                    </div>
                                    <div className="block-feature d-lg-none d-xxl-block">
                                        <i className="ion-android-star-outline"></i>
                                        <a href="/">BUSINESS DIRECTIONS</a>
                                        <p>
                                        Despite the pessimistic prediction of some researchers about the negative impact of robotics, In our opinion are the next step in the development stage of the evolution of mechanization and production automation, which began even before the industrial revolution and the introduction of steam engines. In fact, even before the industrial revolution, there was a specialization, albeit an artisanal one, in "skills". The industrial revolution did not reduce the number of jobs, but in fact became a generator and creation of new jobs and professions, but also the main "engine of science". So today and tomorrow - robotics, not a revolution, but just "evolution", comparable to the industrial revolution and the "convey - organization" of production.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 order-lg-1 d-flex align-items-end">
                                <div  className="relative  d-flex">
                                </div>
                            </div>
                        </div>
                    </div>
                
            </div>

        </section>
        </>
    )
}

export default About
