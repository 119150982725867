import React from 'react'

function Map() {
    return (
        <>
         <div className="google-map">
            <iframe title="Our Address" src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d139140.43322668358!2d24.048131769373835!3d56.9818740409964!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sse!4v1630779060698!5m2!1sen!2sse"></iframe>
        </div>   
        </>
    )
}

export default  Map
